import { useRouter } from "next/router";
import { useEffect } from "react";
import { useRequireAuth } from "./use-auth";
import { SIGNUP_WELCOME_URL } from "./constants";
import { useActiveOrganization } from "@/context/ActiveOrganizationProvider";

export const usePageRedirectToOrgSlug = () => {
  const { user } = useRequireAuth();
  const router = useRouter();
  const { slug } = useActiveOrganization();

  useEffect(() => {
    if (user) {
      if (slug) {
        // Replace so we don't add to the history stack as it will break the back button
        router.replace(`/${slug}${router.asPath}`);
      } else {
        console.log("No slug found, redirecting to signup welcome page");
        if (user.organizations.length === 0) {
          // If the user has no orgs, we redirect to the create org page
          router.replace(SIGNUP_WELCOME_URL);
        }
      }
    }
    // router is not referentially stable https://github.com/vercel/next.js/discussions/29403
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, slug]);
};
